/* Import Lato font */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

/* Set Lato as the default font-family */
body {
  font-family: 'Lato', sans-serif;
}

/* Container styles */
.home-container {
  padding: 20px 30px;
  max-width: 1200px;
  margin: 0 auto;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo {
  width: 120px;
}

.logout-button {
  background-color: #ff1744;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
}

/* Cart grid layout */
.cart-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Responsive columns */
  gap: 20px; /* Space between grid items */
  margin-top: 20px;
}

.cart-grid {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #E1E1E1;
  height: auto;
  max-width: 329px;
}

@media (max-width: 768px) {
  .cart-grid-container {
    grid-template-columns: 1fr; /* Full width for each cart on smaller screens */
  }
}

/* Section-specific styles */
.cart-grid-active {
  border: 1px solid rgba(62, 174, 255, 1);
  background-color: rgba(62, 174, 255, 0.04);
}

.cart-grid-idle {
  border: 1px solid rgba(224, 138, 0, 1);
  background-color: rgba(224, 138, 0, 0.04);
}

.cart-grid-abandoned {
  border: 1px solid rgba(225, 225, 225, 1);
  background-color: rgba(207, 207, 207, 1);
}

/* Cart Grid Header */
.grid-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.grid-header-number {
  font-size: 18px;
  font-weight: bold;
  color: #3d3d3d;
  background-color: #ffffff;
  width: 40px; /* Adjust width to handle two digits */
  height: 40px; /* Adjust height to match width */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #E1E1E1;
  text-align: center;
  box-sizing: border-box;
  padding: 0;
  line-height: normal; /* Ensure text doesn't get extra vertical space */
}



/* Section Titles */
.section-title {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #3d3d3d;
}

.section-title-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

/* Cart Grid Content */
.grid-content {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  margin-top: 10px;
}

/* Grid Item Styles */
.grid-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.grid-item img {
  width: 20px;
}

/* Icon row for alcohol and non-halal icons */
.icon-row {
  display: flex;
  gap: 8px;
}

/* Typography for the text inside grid items */
.typography-grid-item {
  font-family: 'Lato', sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
  text-align: left;
  color: #000;
}

/* Rounded trolley number */
.grid-header-number {
  font-size: 18px;
  font-weight: bold;
  color: #3d3d3d;
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 50%;
  border: 1px solid #E1E1E1;
  display: inline-block;
}

/* Loading spinner */
.loading {
  text-align: center;
  margin-top: 50px;
}

/* Price and icons style */
.price-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price-section {
  display: flex;
  width: 100%; 
  justify-content: space-between;
  align-items: center;
}

.price-row {
  display: flex;
  align-items: center;
}

.price-row img {
  margin-right: 10px; /* Small space between icon and price */
}

.icon-row {
  display: flex;
  gap: 5px; /* Space between icons */
  margin-left: auto;
}

.icon-row img {
  width: 32px;
  height: 32px;
}

/* Add space for price and icon alignment */
.price-section img {
  margin-left: 5px;
}

/* Status message color */
.grid-header-status {
  font-size: 16px;
  margin-left: 10px;
}

 .cart-active-icon {
  margin-left: auto;   
} 

.status-text {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  text-align: left;
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.blink {
  animation: blink 1s infinite; /* Blink effect every 1 second */
}